import React from 'react';
import { useTranslation } from 'react-i18next';

import { Page } from 'src/components';

import Badge from 'src/assets/images/badge_dekra.png';
import HeaderBanner from 'src/assets/images/dekra_banner.png';
import Section1Banner from 'src/assets/images/section1_banner.png';
import Check from 'src/assets/images/check.png';
import Camping from 'src/assets/images/dekra_camping.png';
import Hotel from 'src/assets/images/dekra_hotel.png';
import Event from 'src/assets/images/dekra_event.jpeg';

const Index = () => {
  const { t } = useTranslation(['dekra', 'common']);

  return (
    <Page>
      <Page.Header className="pt-16 bg-secondary-green-light">
        <div className="container">
          <div className="flex flex-col-reverse lg:flex-row lg:pb-24">
            <div className="items-center py-12 lg:flex-1 lg:flex lg:pt-12 lg:pb-0">
              <div className="max-w-xl">
                <div>
                  <p className="text-xl font-bold text-primary-dark tiempos">
                    {t('dekra:header.title')}
                  </p>
                  <span className="text-5xl leading-10 tracking-wide text-primary-dark tiempos md:text-7xl">
                    {t('dekra:header.tagline')}
                  </span>
                </div>
                <p className="pt-12 text-justify whitespace-pre-wrap text-primary-dark">
                  Sharecare, spécialiste international en santé numérique et le groupe DEKRA, un des
                  leaders mondiaux de la certification et tiers de confiance,
                  <br />
                  créent <strong> Sharecare VERIFIED™ with DEKRA. </strong>
                </p>
                <p className="pt-6 text-justify whitespace-pre-wrap text-primary-dark">
                  Le 1er dispositif 100% on line pour répondre aux exigences des nouveaux
                  standards : prévention du risque sanitaire, démarche RSE, management de la chaîne
                  d’approvisionnement, plan de continuité de votre activité professionnelle…
                </p>
                <div className="flex items-center space-x-6">
                  <img src={Badge} alt="Verified with Dekra" className="h-24 lg:h-32" />
                  <p className="pt-6 text-justify whitespace-pre-wrap text-primary-dark">
                    L’accompagnement avec
                    <strong> la plateforme Sharecare VERIFIED™ with DEKRA intègre :</strong>
                    <br />
                    &bull; La vérification des actions déployées.
                    <br />
                    &bull; Les contrôles à distance opérés par les experts DEKRA.
                    <br />
                    &bull; Le monitoring en temps réel du niveau de conformité.
                    <br />
                    &bull; La sensibilisation & le développement des compétences des équipes.
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:flex-1 lg:block">
              <img src={HeaderBanner} alt="Sharecare | HealthSecurity" className="w-full" />
            </div>
          </div>
        </div>
      </Page.Header>
      <Page.Main className="text-primary-grey">
        <section className="container py-16">
          <div className="text-center">
            <span className="text-4xl text-primary-dark tiempos md:text-5xl">
              {t('dekra:activity.title')}
            </span>
          </div>
          <div className="grid max-w-screen-xl grid-cols-1 pt-8 gap-y-12 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-12 lg:mx-auto">
            <div className="space-y-4">
              <div className="relative">
                <img
                  src={Camping}
                  alt="Camping"
                  className="relative object-cover w-full rounded-lg"
                  style={{ height: '219px' }}
                />
                <div className="absolute inset-0 rounded-lg opacity-60 bg-gradient-to-t from-black" />
              </div>
              <span className="flex items-center justify-center text-xl font-bold">
                {t('dekra:activity.items.0.title')}
              </span>
            </div>
            <div className="space-y-4">
              <div className="relative">
                <img
                  src={Hotel}
                  alt="Camping"
                  className="relative object-cover w-full rounded-lg"
                  style={{ height: '219px' }}
                />
                <div className="absolute inset-0 rounded-lg opacity-60 bg-gradient-to-t from-black" />
              </div>
              <span className="flex items-center justify-center text-xl font-bold">
                {t('dekra:activity.items.1.title')}
              </span>
            </div>
            <div className="space-y-4">
              <div className="relative">
                <img
                  src={Event}
                  alt="Camping"
                  className="relative object-cover w-full rounded-lg"
                  style={{ height: '219px' }}
                />
                <div className="absolute inset-0 rounded-lg opacity-60 bg-gradient-to-t from-black" />
              </div>
              <span className="flex items-center justify-center text-xl font-bold">
                {t('dekra:activity.items.2.title')}
              </span>
            </div>
          </div>
        </section>
        <section className="container py-16">
          <div className="text-center">
            <span className="text-4xl text-primary-dark tiempos md:text-5xl">
              {t('dekra:how.title')}
              <br />
              {t('dekra:how.tagline')}
            </span>
          </div>
          <div className="flex flex-col items-center pt-8 space-y-12 md:flex-row md:space-y-0 md:space-x-6">
            <img
              src={Section1Banner}
              alt="Sharecare | HealthSecurity"
              className="object-cover w-full h-full md:w-1/2 md:h-auto"
            />
            <div className="space-y-4">
              <div className="flex items-center space-x-4">
                <img src={Check} alt="Sharecare | HealthSecurity" className="h-full" />
                <span>{t('dekra:how.items.chatbot')}</span>
              </div>
              <div className="flex items-center space-x-4">
                <img src={Check} alt="Sharecare | HealthSecurity" className="h-full" />
                <span>{t('dekra:how.items.protocols')}</span>
              </div>
              <div className="flex items-center space-x-4">
                <img src={Check} alt="Sharecare | HealthSecurity" className="h-full" />
                <span>{t('dekra:how.items.management')}</span>
              </div>
            </div>
          </div>
        </section>
      </Page.Main>
    </Page>
  );
};

export default Index;
