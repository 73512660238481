import React from 'react';
import { useTranslation } from 'react-i18next';

import { Page } from 'src/components';

import HeaderBanner from 'src/assets/images/vulcain_banner.png';
import Section1Banner from 'src/assets/images/section1_banner.png';
import Check from 'src/assets/images/check.png';

const Index = () => {
  const { t } = useTranslation('vulcain');

  const openChatbot = () => {
    document.getElementById('vulcain-chatbot').classList.add('open');
  };

  return (
    <Page>
      <Page.Header className="pt-16 bg-secondary-green-light">
        <div className="container">
          <div className="flex flex-col-reverse justify-between pb-12 lg:flex-row">
            <div className="pt-12">
              <div className="max-w-xl">
                <h1 className="text-5xl text-primary-dark tiempos">
                  {t('vulcain:header.tagline')}
                </h1>
                <p className="pt-6 text-primary-dark">
                  {t('vulcain:header.introductions.0')}
                  <br />
                  <br />
                  {t('vulcain:header.introductions.1')}
                  <br />
                  <br />
                  {t('vulcain:header.introductions.2')}
                  <br />
                  <br />
                  {t('vulcain:header.introductions.3')}
                  <br />
                  <br />
                  {t('vulcain:header.introductions.4')}
                </p>
                <div className="flex items-center w-2/3 pt-4 text-center">
                  <button
                    type="button"
                    onClick={() => openChatbot()}
                    className="w-full p-2 text-white transition border-2 rounded-md md:px-4 lg:px-6 focus:outline-none bg-primary-light border-primary-light hover:bg-primary-light-hover hover:border-primary-light-hover focus:border-primary-light-hover focus:bg-primary-light-hover"
                  >
                    {t('buttons.login')}
                  </button>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end">
              <img src={HeaderBanner} alt="Sharecare | HealthSecurity" />
            </div>
          </div>
        </div>
      </Page.Header>
      <Page.Main className="text-primary-grey">
        <section className="container pt-16 pb-16">
          <div className="m-auto text-center">
            <span className="text-4xl text-primary-dark tiempos md:text-5xl">
              {t('vulcain:how.tagline')}
            </span>
          </div>
          <div className="flex flex-col items-center pt-8 space-y-12 md:flex-row md:space-y-0 md:space-x-6">
            <img
              src={Section1Banner}
              alt="Sharecare | HealthSecurity"
              className="object-cover w-full h-full md:w-1/2 md:h-auto"
            />
            <div className="space-y-4">
              <div className="flex items-center space-x-4">
                <img src={Check} alt="Sharecare | HealthSecurity" className="h-full" />
                <span>{t('vulcain:how.items.chatbot')}</span>
              </div>
              <div className="flex items-center space-x-4">
                <img src={Check} alt="Sharecare | HealthSecurity" className="h-full" />
                <span>{t('vulcain:how.items.protocols')}</span>
              </div>
              <div className="flex items-center space-x-4">
                <img src={Check} alt="Sharecare | HealthSecurity" className="h-full" />
                <span>{t('vulcain:how.items.management')}</span>
              </div>
            </div>
          </div>
        </section>
      </Page.Main>
    </Page>
  );
};

export default Index;
