/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';

import { setAuthenticating } from 'src/store';
import CONFIG from 'src/config';
import { FLOWS } from 'src/config/shared';
import { Page, Button } from 'src/components';

import HeaderBanner from 'src/assets/images/vaccine_banner.png';
import VideoSteps from 'src/assets/videos/vaccine_steps.mp4';

const Index = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { instance } = useMsal();
  const { t } = useTranslation(['vaccine']);

  const sign = async () => {
    localStorage.setItem('redirect', 'true');
    dispatch(setAuthenticating(true));
    await instance.loginRedirect(CONFIG.FLOWS[FLOWS.SIGNIN]);
  };

  return (
    <Page>
      <Page.Header className="pt-16 bg-secondary-green-light">
        <div className="container">
          <div className="flex flex-col-reverse lg:flex-row lg:pb-24">
            <div className="items-center py-12 lg:flex-1 lg:flex lg:pt-12 lg:pb-0">
              <div className="max-w-xl">
                <p className="text-lg font-bold text-primary-dark tiempos">
                  {t('vaccine:header.title')}
                </p>
                <span className="text-5xl leading-10 tracking-wide text-primary-dark tiempos md:text-7xl">
                  {t('vaccine:header.tagline')}
                </span>
                <p className="pt-6 text-primary-dark">{t('vaccine:header.introductions.first')}</p>
                <div className="flex items-center w-2/3 pt-4 text-center">
                  {user.authenticated ? (
                    <Button as="link" to="/dashboard/">
                      {t('common:navigation.buttons.dashboard')}
                    </Button>
                  ) : (
                    <Button
                      gtag="Click_signin"
                      onClick={() => sign()}
                      disabled={user.isAuthenticating}
                      loading={user.isAuthenticating}
                    >
                      {t('cars:buttons.login')}
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="lg:flex-1 lg:block">
              <img src={HeaderBanner} alt="Sharecare | HealthSecurity" className="w-full" />
            </div>
          </div>
        </div>
      </Page.Header>
      <Page.Main>
        <section className="container py-24">
          <div className="flex flex-col items-center justify-center space-y-8 text-center">
            <h4 className="text-4xl text-primary-dark tiempos md:text-5xl">
              {t('vaccine:how.title')}
            </h4>
            <p className="max-w-screen-md lg:px-8">{t('vaccine:how.description')}</p>
            <video src={VideoSteps} className="max-w-screen-md" controls>
              Sorry, your browser doesn&apos;t support embedded videos.
            </video>
          </div>
        </section>
        <section className="container py-24">
          <div className="flex items-center justify-center text-center">
            <h4 className="max-w-xl text-4xl text-primary-dark tiempos md:text-5xl">
              {t('vaccine:scalable.title')}
            </h4>
          </div>
          <div className="grid grid-cols-1 gap-8 pt-8 md:grid-cols-2 lg:grid-cols-3">
            <div className="flex flex-col px-4 py-10 rounded-lg shadow-lg">
              <span className="text-lg font-bold">{t('vaccine:scalable.items.1.title')}</span>
              <span className="pt-2 text-sm text-secondary">
                {t('vaccine:scalable.items.1.description')}
              </span>
            </div>
            <div className="flex flex-col px-4 py-10 rounded-lg shadow-lg">
              <span className="text-lg font-bold">{t('vaccine:scalable.items.2.title')}</span>
              <span className="pt-2 text-sm text-secondary">
                {t('vaccine:scalable.items.2.description')}
              </span>
            </div>
            <div className="flex flex-col px-4 py-10 rounded-lg shadow-lg">
              <span className="text-lg font-bold">{t('vaccine:scalable.items.3.title')}</span>
              <span className="pt-2 text-sm text-secondary">
                {t('vaccine:scalable.items.3.description')}
              </span>
            </div>
            <div className="flex flex-col px-4 py-10 rounded-lg shadow-lg">
              <span className="text-lg font-bold">{t('vaccine:scalable.items.4.title')}</span>
              <span className="pt-2 text-sm text-secondary">
                {t('vaccine:scalable.items.4.description')}
              </span>
            </div>
            <div className="flex flex-col px-4 py-10 rounded-lg shadow-lg">
              <span className="text-lg font-bold">{t('vaccine:scalable.items.5.title')}</span>
              <span className="pt-2 text-sm text-secondary">
                {t('vaccine:scalable.items.5.description')}
              </span>
            </div>
          </div>
        </section>
      </Page.Main>
    </Page>
  );
};

export default Index;
